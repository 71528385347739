import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

interface EnvObj {
  Local: URLTYPE;
  Stage: URLTYPE;
  Production: URLTYPE;
}
interface URLTYPE {
  apiUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public Environment: 'Production' | 'Local' | 'Stage' = environment.production ? 'Production' : "Local"; //Production,Stage,Local

  private EnvironmentObject: EnvObj = {
    Local: {
      // apiUrl: 'https://viotal4.loca.lt/',  // Local host for remote connection

      // apiUrl:'http://13.234.47.193:3007/'  // Staging on Viotal aws
      // apiUrl:'http://localhost:3023/'      // Localhost connected with same machine

       //apiUrl:'http://15.207.209.194:3009/'  // Production on EnE-Masterclass
      //  apiUrl: 'https://ene-masterclass.com/'

     apiUrl:'http://15.207.209.194:3012/'  // Test-Staging on EnE-Masterclass

    },
    "Stage": {
      apiUrl: location.origin+'/'
    },
    "Production": {
      apiUrl: location.origin+'/'
      // apiUrl: 'https://ene-masterclass.com/'

    }
  }
  constructor() { }

  getBaseURI(): string {
    return this.EnvironmentObject[this.Environment]['apiUrl'];
  }
}
